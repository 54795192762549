import React, { useState } from "react";


// const CheckboxeSection = ({
//   vocs,
//   categories,
//   userInput,
//   checkboxClick,
//   className,
//   CheckboxComp
// }) => {
//   //console.log( vocs)


//   const vocs2 = [
//     vocs.find((voc) => voc.name === "Integration"),
//     vocs.find((voc) => voc.name === "Category"),
//     vocs.find((voc) => voc.name === "Processes"),
//     //vocs.find((voc) => voc.name === "User"),
//   ];

//   const sections = vocs2.map((voc, index) => (
//     <CheckboxGroup
//       key={index}
//       title={checkboxGroupTitle(voc.name)}
//       items={categories.filter((c) => c.vocabulary === voc.vocabulary)}
//       onChange={checkboxClick}
//       values={userInput.tags}
//       CheckboxComp={CheckboxComp}
//     />
//   ));

//   return (
//     <div className={className}>
//       <div className="--ml-2 space-y-8 lg:space-y-12">{sections}</div>
//     </div>
//   );
// };

const checkboxGroupTitle = (name) => {
  let title = "";
  switch (name) {
    case "Category":
      title = "Departments";
      break;
    case "Integration":
      title = "Integrations";
      break;
    default:
      title = name;
      break;
  }

  return title;
};

const CheckboxIntegrations = ({
  item,
  checked,
  onChange,
  values,
  ...props
}) => {
  
  return (
    <div className="flex flex-row items-center  text-sm gap-x-3">
      <input
        type="checkbox"
        className="mr-2 text-[#5E718D]  min-w-[16px] min-h-[16px]  font-normal border-[#9E9E9E] "
        //key={index}
        id={item.id}
        checked={checked}
        onChange={(e) => onChange(item, e.target.checked)}
      />

      <label
        htmlFor={item.id}
        className={
          " font-normal --flex-grow text-wrap	text-[#727C8D] " 
        }
      >
          <span className="">{item.name}</span>
      </label>
    </div>
  );
};

const CheckboxGroupTitleComp = ({ title }) => {

  return (
    <h3 className="text-sm md:text-lg font-medium text-[#28293D]">{title}</h3>
  )
}


const CheckboxGroup = ({ title, items, onChange, values, CheckboxComp, TitleComp = CheckboxGroupTitleComp, limit = 8  }) => {
  const [show, setShow] = useState(false);

  //const limit = 8;
  const count = items.length;

  //console.log( items, values )

  const notNew = items
    //.filter((item) => !item.new)
    .sort((a, b) => a.name.localeCompare(b.name));

  // const yesNew = items
  //   .filter((item) => !!item.new)
  //   .sort((a, b) => a.name.localeCompare(b.name));


  const all = [...notNew /*, ...yesNew*/];

  const hiddenItems = all.slice(limit);
  const hiddenSelectedItems = hiddenItems.filter((item) =>
    values.map((v) => v.id).includes(item.id)
  );

  const dispalyButton = count > limit && hiddenSelectedItems.length === 0;
  const some =
    show || hiddenSelectedItems.length > 0 ? all : all.slice(0, limit);

  return (
    <div className="space-y-5">
      {/* <h3 className="text-sm md:text-lg font-medium text-[#28293D]">{title}</h3> */}
      <TitleComp title={title} />
      <div className="space-y-3 text-base">
        {some.map((item, index) => (
          <CheckboxComp
            item={item}
            checked={values.map((v) => v.id).includes(item.id)}
            onChange={onChange}
            key={index}
          />
        ))}
        {dispalyButton && !show && (
          <button
            onClick={() => setShow(!show)}
            className="text-blue text-sm font-medium hover:underline"
          >
            {!show ? "More" : "Show less"}
          </button>
        )}
      </div>
      {title !== "Integration" && (
        <div className="h-[31px] border-b-[1px] border-[#E9EFF6] hidden lg:block"></div>
      )}
    </div>
  );
};


// const CheckboxGroupOld = ({  items, onChange, values, CheckboxComp, limit = 8  }) => {
//   const [show, setShow] = useState(false);

//   //const limit = 8;
//   const count = items.length;

//   //console.log( items, values )

//   const notNew = items
//     //.filter((item) => !item.new)
//     .sort((a, b) => a.name.localeCompare(b.name));

//   // const yesNew = items
//   //   .filter((item) => !!item.new)
//   //   .sort((a, b) => a.name.localeCompare(b.name));

//     //console.log(notNew, yesNew)
//   const all = [...notNew /*, ...yesNew*/];

//   const hiddenItems = all.slice(limit);
//   const hiddenSelectedItems = hiddenItems.filter((item) =>
//     values.map((v) => v.id).includes(item.id)
//   );

//   const dispalyButton = count > limit && hiddenSelectedItems.length === 0;
//   const some =
//     show || hiddenSelectedItems.length > 0 ? all : all.slice(0, limit);

//   return (
//     <div className="space-y-5">
//       <div className="space-y-3 text-base">
//         {some.map((item, index) => (
//           <CheckboxComp
//             item={item}
//             checked={values.map((v) => v.id).includes(item.id)}
//             onChange={onChange}
//             key={index}
//           />
//         ))}
//         {dispalyButton && !show && (
//           <button
//             onClick={() => setShow(!show)}
//             className="text-blue text-sm font-medium hover:underline"
//           >
//             {!show ? "More" : "Show less"}
//           </button>
//         )}
//       </div>
      
//     </div>
//   );
// };


export {CheckboxGroup, CheckboxIntegrations, checkboxGroupTitle};
