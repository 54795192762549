import React, { useEffect, useState, useMemo } from "react";
import { Link, graphql } from "gatsby";
import Layout from "../components/layout";
import Seo from "../components/Seo";
import Template from "../components/marketplace/template-card";
import Metric, { MetricPreview } from "../components/marketplace/metric";
import {
  extendsTemplates,
  vocsNames,
  filterTemplates,
  filterMatrics,
  extendsMetrics,
} from "../util/marketplace";

import closeIcon from "../images/close-x.svg";
import Pagination from "../components/marketplace/pagination";
import { checkboxGroupTitle } from "../components/marketplace/checkboxes";
import { UserCommentForm } from "../components/marketplace/comment";
import { RequestForm, Modal } from "../components/forms/request-form";
import BlockContentSectionContent from "../components/blocks/content-section";

//const isProduction = `${process.env.GATSBY_ACTIVE_ENV}` === "production";

const MarketplaceSearchPage = ({
  data: { categories2, templates2, metrics2, block },
  location,
}) => {
  const buildQueryString = (s) => {
    //const vocs = ['category', 'persona', 'connectors', 'workflows']

    const components = [];
    for (let voc of vocsNames) {
      //console.log(voc);
      const values = s.tags
        .filter((t) => t.vocabulary === voc.vocabulary)
        .map((t) => t.name.toLowerCase())
        .map((t) => encodeURIComponent(t));

      if (values.length > 0) {
        components.push(`${voc.name.toLowerCase()}=${values.join(",")}`);
      }
    }

    if (s.query !== "") {
      components.push(`${"text"}=${s.query}`);
    }

    const q = "?" + components.join("&");
    return q;
  };

  const [userInput, setUserInput] = useState({
    tags: [],
    query: "",
  });

  const checkboxClick = (value, checked) => {
    const s = { ...userInput };
    if (checked) {
      s.tags.push(value);
    } else {
      s.tags = s.tags.filter((e) => e.id !== value.id);
    }

    setUserInput(s);

    const url = buildQueryString(s);
    window.history.pushState({}, "", url);
  };

  const queryChange = (value) => {
    const s = { ...userInput, query: value };
    setUserInput(s);

    const url = buildQueryString(s);
    window.history.pushState({}, "", url);
  };

  const extendsCategories = (categories, metrics) => {
    const c2 = categories.map((c) => {
      let isNew = false;

      if (c.vocabulary === "connectors") {
        isNew =
          metrics
            .map((m) => m.metric)
            .filter((m) => m.connectors.includes(c.name) && !!m.preview)
            .length > 0;
      }
      return {
        ...c,
        new: isNew,
      };
    });

    return c2;
  };

  //console.log( templates2.nodes.map(n => ({title: n.title, connectors: n.connectors , metrics: n.metrics})))
  const templates0 = useMemo(
    () => extendsTemplates(templates2.nodes),
    [templates2]
  );
  //console.log( templates0.map(n => ({title: n.title, connectors: n.connectors , metrics: n.metrics})))
  const metrics0 = useMemo(
    () => extendsMetrics(metrics2.nodes, templates2.nodes),
    [metrics2, templates2]
  );

  const dTemplates = filterTemplates(templates0, userInput);
  const dMatrics = filterMatrics(metrics0, userInput);

  const [activeTab, setActiveTab] = useState("templates");
  const [showTopForm, setShowTopForm] = useState(false);
  const [showFilters, setShowFilters] = useState(false);

  const showNoReultsForm = dTemplates.length === 0 && dMatrics.length === 0;

  useEffect(() => {
    const initInput = {
      tags: [],
      query: "",
    };

    const searchParams = new URLSearchParams(location.search);

    for (let voc of vocsNames) {
      if (searchParams.has(voc.name.toLowerCase())) {
        const terms = searchParams.get(voc.name.toLowerCase()).split(",");

        terms.map((t) => {
          const tt = categories2.nodes.find(
            (c) => c.name.toLowerCase() === t && c.vocabulary === voc.vocabulary
          );

          if (tt) {
            initInput.tags.push(tt);
          }
          return null;
        });
      }
    }

    if (searchParams.has("text")) {
      initInput.query = searchParams.get("text");
    }

    setUserInput(initInput);

    if (searchParams.has("tab")) {
      setActiveTab(searchParams.get("tab"));
    }
  }, [location, categories2]);

  const showBothTabs = !(userInput.tags.length === 0 && userInput.query === "");

  const seo = {
    title: "Browse GTM Templates & Metrics by Category | Metrics Library",
    description:
      "Refine and explore the Sightfull metrics library: filter GTM Templates & Metrics for enhanced revops, sales, and marketing excellence.",
    meta: [
      {
        name: "image",
        property: `og:image`,
        content: "/Marketplace_-_OG-1.png",
      },
      {
        property: `og:image:alt`,
        content: "Metrics Library",
      },
    ],
  };

  const handleClick = (e) => {
    setShowTopForm(true);
  };

  const handleClose = (e) => {
    setShowTopForm(false);
  };

  const handleResetFilter = () => {
    //console.log("Resetting filter")
    setUserInput({ ...userInput, tags: [] });
  };

  const categories = useMemo(
    () => extendsCategories(categories2.nodes, metrics0),
    [categories2, metrics0]
  );

  return (
    <Layout showCta={false} isMarketplace={true}>
      <Seo {...seo} />
      <div className="h-[20px] lg:h-[70px]"></div>
      
      <Hero
        title="Template Library"
        label="Templates & Metrics"
        description="Explore our library of pre-defined, popular metric templates. 
        <br/>Choose any template, connect your data, and get immediate insights."
      />

      <section className="pt-[26px] pb-[64px] hidden lg:block">
        <div className="--container max-w-screen-xl mx-auto">
          <div className="flex flex-col gap-x-8 lg:flex-row justify-between">
            <div className="lg:min-w-[276px]    ">
              <CheckboxeSection
                vocs={vocsNames}
                categories={categories}
                userInput={userInput}
                checkboxClick={checkboxClick}
                className=" py-4 space-y-7 border-r border-[#C9CDD3] pr-8 "
              />
            </div>
            <div className={"flex-grow space-y-12"}>
              <div className="flex flex-row justify-between ">
                <div className="">
                  <Tabs
                    activeTab={activeTab}
                    setActiveTab={setActiveTab}
                    show={!showBothTabs && !showTopForm}
                  />
                </div>
                <div className=" lg:w-4/12 ">
                  <div className="">
                    <SearchInput
                      value={userInput.query}
                      onChange={(e) => queryChange(e.target.value)}
                      show={!showTopForm}
                    />
                  </div>
                </div>
              </div>

              <div className="hidden lg:block">
                <UserCommentForm
                  title="Can't find what you're looking for?"
                  subtitle="Try removing applied filters, or ask us:"
                  show={showNoReultsForm && !showTopForm}
                />
              </div>

              <Results
                showBothTabs={showBothTabs}
                activeTab={activeTab}
                templates={dTemplates}
                metrics={dMatrics}
                show={!showTopForm}
              />


            <Component data={block} context="marketplace-libraray" onClick={handleClick} />


{/* 
              <HeroBlock
                data={block.relationships.content}
                onClick={handleClick}
              /> */}

              <UserCommentForm
                title="Having trouble finding what you need?"
                subtitle="Let us know, and we'll help you find it."
                onClose={handleClose}
                show={showTopForm}
              />
            </div>
          </div>
        </div>
      </section>

      <section className="py-[16px] lg:hidden">
        <div className="container ">
          <div
            className={
              "fixed left-0 right-0 bottom-0 z-[5]  bg-white  px-6 transition duration-1000  overflow-y-scroll top-[70px]  " +
              (showFilters ? "   translate-y-0 " : "    translate-y-full	 ")
            }
          >
            {/* <div>content</div> */}

            <div className="flex flex-row items-center sticky right-0 left-0 top-0 bg-white w-full  py-4 gap-4 ">
              <button
                onClick={() => {
                  setShowFilters(false);
                }}
              >
                <img src={closeIcon} alt="close w-4 h-4" />
              </button>
              <div className="flex-grow text-lg fomt-medium leading-[24px] text-[#28293D]">
                Filters
              </div>
              <div>
                <button
                  className="text-[#1B4DFF] text-sm font-medium"
                  onClick={handleResetFilter}
                >
                  Clear filters
                </button>
              </div>
            </div>
            <div className="--mt-[70px]">
              <CheckboxeSection
                vocs={vocsNames}
                categories={categories}
                userInput={userInput}
                checkboxClick={checkboxClick}
                className={
                  " py-4 space-y-7 md:border-r border-[#E9EFF6] pr-8    " +
                  (showFilters ? "" : "")
                }
              />
            </div>
          </div>

          {!showTopForm && (
            <div className="flex flex-row justify-between gap-2.5">
              <SearchInput
                value={userInput.query}
                onChange={(e) => queryChange(e.target.value)}
                show={true}
              />
              <div>
                <button
                  className={
                    "border border-[#D7DFE9] p-2.5 rounded hover:bg-[#F2F5FF] " +
                    (showFilters ? " bg-[#F2F5FF]" : " ")
                  }
                  onClick={() => {
                    setShowFilters(!showFilters);
                  }}
                >
                  <img
                    src="/filter.svg"
                    alt="open filters"
                    className="h-[20px]"
                  />
                </button>
              </div>
            </div>
          )}

          <div>
            <div className="h-4" />
            <div className="lg:hidden">
              <UserCommentForm
                title="Can't find what you're looking for?"
                subtitle="Try removing applied filters, or ask us:"
                show={showNoReultsForm}
              />
            </div>
          </div>

          <div>
            {/* <div className="flex flex-row justify-start hidden">
              <button>x</button>
              <div className="flex-grow">Filters</div>
              <div>Clear filters</div>
            </div> */}
            {/* <div>
              <CheckboxeSection
                vocs={vocsNames}
                categories={categories}
                userInput={userInput}
                checkboxClick={checkboxClick}
                className={
                  " py-4 space-y-7 border-r border-[#E9EFF6] pr-8  lg:hidden " +
                  (showFilters ? "" : "hidden")
                }
              />
            </div> */}
          </div>

          <div className={"flex-grow space-y-12"}>
            <Tabs
              activeTab={activeTab}
              setActiveTab={setActiveTab}
              show={!showTopForm && !showBothTabs}
            />

            <Results
              showBothTabs={showBothTabs}
              activeTab={activeTab}
              templates={dTemplates}
              metrics={dMatrics}
              show={!showTopForm}
            />


<Component data={block} context="marketplace-libraray" onClick={handleClick} />

{/* 
            <HeroBlock
              data={block.relationships.content}
              onClick={handleClick}
            /> */}

            <UserCommentForm
              title="Having trouble finding what you need?"
              subtitle="Let us know, and we'll help you find it."
              onClose={handleClose}
              show={showTopForm}
            />
          </div>
        </div>
      </section>
    </Layout>
  );
};


const Hero = ({
  title,
  description,
  ...props
}) => {
  //console.log( props )
  return (
    <section
      className=""
      
    >
      <div className=" max-w-[14400px] mx-auto pt-12 lg:pt-[74px] pb-[84px] px-6 lg:px-0 bg-no-repeat bg-top --md:h-[482px]"
      style={{
        backgroundImage:
          "url('/background/background-image-hero-integrations-2.png')",
      }}
      >
      <div className=" space-y-[102px] ">
        <div className="space-y-8">
          <div className=" text-center">
            <div className="inline-block rounded-[4px] text-[#1B4DFF] bg-[#F2F5FF] py-[7px] px-[18px] leading-[20px] ">
              {props.label}
            </div>
          </div>
          <div className="">
            <h1
              className="text-center  text-[40px] lg:text-[60px] font-medium leading-[44px] lg:leading-[66px] lg:tracking-[-0.03em] text-[#3D434C] max-w-[900px] mx-auto"
              dangerouslySetInnerHTML={{ __html: title }}
            />
          </div>

          <div
            className="text-center text-[20px] leading-[32px] text-[#3D434C] max-w-[900px] mx-auto"
            dangerouslySetInnerHTML={{ __html: description }}
          />
        </div>
      </div>
      </div>
    </section>
  );
};


const Tabs = ({ activeTab, setActiveTab, show }) => {
  if (!show) return;
  return (
    <div className=" border bg-[#F0F3F9] p-1 rounded-[4px] gap-1">
      <div className="space-x-6 text-[#8897AE] text-sm font-medium leading-[20px] flex flex-row justify-between">
        <Tabs.Button
          title={"Templates"}
          onClick={() => setActiveTab("templates")}
          active={activeTab === "templates"}
        />
        <Tabs.Button
          title={"Metrics"}
          onClick={() => setActiveTab("metrics")}
          active={activeTab === "metrics"}
        />
        {/* <Tabs.Button
          title={"Integrations"}
          onClick={() => setActiveTab("integrations")}
          active={activeTab === "integrations"}
        /> */}
      </div>
    </div>
  );
};

Tabs.Button = ({ title, className, active, ...props }) => {
  const base = "w-1/2 lg:w-[200px] py-[6px] ";
  return (
    <button
      className={
        base +
        (active
          ? "text-[#2D3643] bg-white rounded-[4px]"
          : " hover:text-[#5E718D] font-normal")
      }
      ///onClick={() => setActiveTab("metrics")}
      {...props}
    >
      <h2>{title}</h2>
    </button>
  );
};

const CheckboxeSection = ({
  vocs,
  categories,
  userInput,
  checkboxClick,
  className,
}) => {
  //console.log( vocs)

  const vocs2a = [
    vocs.find((voc) => voc.name === "Integration"),
    //vocs.find((voc) => voc.name === "Category"),
    //vocs.find((voc) => voc.name === "Processes"),
  ];

  const vocs2b = [
    //vocs.find((voc) => voc.name === "Integration"),
    vocs.find((voc) => voc.name === "Category"),
    vocs.find((voc) => voc.name === "Processes"),
  ];

  const section_integrations = vocs2a.map((voc, index) => (
    <CheckboxGroupIntegrations
      key={index}
      title={checkboxGroupTitle(voc.name)}
      items={categories.filter((c) => c.vocabulary === voc.vocabulary)}
      onChange={checkboxClick}
      values={userInput.tags}
    />
  ));

  const sections = vocs2b.map((voc, index) => (
    <CheckboxGroup
      key={index}
      title={checkboxGroupTitle(voc.name)}
      items={categories.filter((c) => c.vocabulary === voc.vocabulary)}
      onChange={checkboxClick}
      values={userInput.tags}
    />
  ));

  return (
    <div className={className}>
      <div className="--ml-2 space-y-8 lg:space-y-12">
        {section_integrations}
        {sections}
      </div>
    </div>
  );
};

const CheckboxGroupIntegrations = ({ title, items, onChange, values }) => {

  const [query, setQuery] = useState("");
  const [show, setShow] = useState(false);

  if (query !== "") {
    //console.log("query", query);
  }

  const checkedOrMatchText = (item ) => {

    const checked = values.map((v) => v.id).includes(item.id);
    return item.text.includes(query.toLowerCase()) || checked
  }

 
  const items2 = items
    .map((item) => ({ ...item, text: item.name.toLowerCase() }))
    .filter((item) => checkedOrMatchText(item) )
    ;

  const limit = 8;
  const count = items2.length;

  const notNew = items2
    .filter((item) => !item.new)
    .sort((a, b) => a.name.localeCompare(b.name));

  const yesNew = items2
    .filter((item) => !!item.new)
    .sort((a, b) => a.name.localeCompare(b.name));

  const all = [...notNew, ...yesNew];


  const dispalyButton = count > limit 
  const some = show ? all : all.slice(0, limit);

  return (
    <div className="space-y-4">
      <div className="flex flex-row justify-between gap-x-3 items-center">
        <h3 className="text-sm  font-medium text-[#28293D]">{title}</h3>
        <Link className="text-sm font-medium leading-[20px] text-[#1B4DFF] hidden md:block" to={"/integrations/"}>
          View All
        </Link>
      </div>
      <div className="max-w-[243px]">
        <SearchInputIntegrations
          value={query}
          onChange={(e) => {
            setQuery(e.target.value);
          }}
          show={true}
        />
      </div>
      <div className="space-y-3 text-sm">
        {some.map((item, index) => {
          const checked = values.map((v) => v.id).includes(item.id);
          return (
            <div
              key={index}
              className="flex flex-row items-center justify-start gap-x-3 "
            >
              <input
                type="checkbox"
                className=" text-[#5E718D] text-sm w-[16px] h-[16px] font-normal border-[#AFBACA] "
                id={item.id}
                checked={checked}
                onChange={(e) => onChange(item, e.target.checked)}
              />
              <label
                htmlFor={item.id}
                className={
                  "font-normal  text-sm flex-grow " +
                  (checked
                    ? "text-[#2D3643]"
                    : "text-[#5E718D] hover:text-[#2D3643]")
                }
              >
                <span className="flex flex-row justify-between items-center">
                  <span className="">{item.name}</span>
                </span>
              </label>

              {item.new === true && (
                <span className="text-[11px] uppercase font-medium leading-[20px] bg-[#F2F5FF] text-[#1B4DFF] px-2 py-[2px] rounded-[4px]">
                  New
                </span>
              )}
            </div>
          );
        })}
        {dispalyButton && !show && (
          <button
            onClick={() => setShow(!show)}
            className="text-[#1B4DFF] leading-[20px] text-sm font-medium hover:underline"
          >
            {!show ? "More" : "Show less"}
          </button>
        )}
      </div>
      <div className="h-[31px] border-b-[1px] border-[#C9CDD3] hidden lg:block"></div>
    </div>
  );
};

const CheckboxGroup = ({ title, items, onChange, values }) => {
  //console.log( items )
  const [show, setShow] = useState(false);

  const limit = 8;
  const count = items.length;

  //console.log( items )

  const notNew = items
    .filter((item) => !item.new)
    .sort((a, b) => a.name.localeCompare(b.name));

  const yesNew = items
    .filter((item) => !!item.new)
    .sort((a, b) => a.name.localeCompare(b.name));

  const all = [...notNew, ...yesNew];

  const hiddenItems = all.slice(limit);
  const hiddenSelectedItems = hiddenItems.filter((item) =>
    values.map((v) => v.id).includes(item.id)
  );

  const dispalyButton = count > limit && hiddenSelectedItems.length === 0;
  const some =
    show || hiddenSelectedItems.length > 0 ? all : all.slice(0, limit);

  return (
    <div className="space-y-4">
      <h3 className="text-sm font-medium text-[#28293D]">{title}</h3>
      <div className="space-y-3 text-sm">
        {some.map((item, index) => {
          const checked = values.map((v) => v.id).includes(item.id);
          return (
            <div key={index} className="flex flex-row items-center justify-start gap-x-3 ">
              <input
                type="checkbox"
                className=" text-[#5E718D] text-sm w-[16px] h-[16px] font-normal border-[#AFBACA] "
                key={index}
                id={item.id}
                checked={checked}
                onChange={(e) => onChange(item, e.target.checked)}
              />
              <label
                htmlFor={item.id}
                className={
                  " font-normal  text-sm " +
                  (checked
                    ? "text-[#2D3643]"
                    : "text-[#5E718D] hover:text-[#2D3643]")
                }
              >
                <span className="flex flex-row justify-between items-center">
                  <span className="">{item.name}</span>
                </span>
              </label>

              {/* <div>New</div> */}
              {item.new === true && (
                <span className="text-[11px] uppercase font-medium leading-[20px] bg-[#F2F5FF] text-[#1B4DFF] px-2 py-[2px] rounded-[2px]">
                  New
                </span>
              )}
            </div>
          );
        })}
        {dispalyButton && !show && (
          <button
            onClick={() => setShow(!show)}
            className="text-blue text-sm font-medium hover:underline"
          >
            {!show ? "More" : "Show less"}
          </button>
        )}
      </div>
      {title !== "Processes" && (
        <div className="h-[31px] border-b-[1px] border-[#C9CDD3] hidden lg:block"></div>
      )}
    </div>
  );
};

const Results = ({
  showBothTabs,
  activeTab,
  templates,
  metrics,
  intgrations = [],
  show,
}) => {
  return show ? (
    <div className="space-y-12">
      <div className="space-y-[44px]">
        <Results.Section
          show={
            templates.length > 0 && (showBothTabs || activeTab === "templates")
          }
          showTitle={showBothTabs}
          title={"Templates"}
        >
          <Pagination
            Card={Template}
            items={templates.map((t) => ({
              ...t,
              wrapperClassName: "",
              className:
                "border border-[#C9CDD3] p-4 rounded-lg flex flex-row justify-start gap-x-3 hover:border-[#AFBACA] bg-white",
            }))}
            className="grid grid-cols-1 md:grid-cols-2 gap-4  "
          />
        </Results.Section>

        <Results.Section
          show={metrics.length > 0 && (showBothTabs || activeTab === "metrics")}
          showTitle={showBothTabs}
          title={"Metrics"}
        >
          <SectionMetrics items={metrics} show={true} />
        </Results.Section>

        
      </div>
    </div>
  ) : (
    false
  );
};

Results.Section = ({ title, children, show, showTitle }) => {
  return show ? (
    <div className="space-y-6">
      <Results.Title title={title} show={showTitle} />
      <div>{children}</div>
    </div>
  ) : (
    false
  );
};

Results.Title = ({ title, show }) => {
  return show ? <h2 className="text-[24px] font-semibold">{title}</h2> : false;
};

Results.SubSection = ({ show, title, isNew = false, children }) => {
  return show ? (
    <section className="space-y-4">
      <div className="text-lg text-[#3D434C] font-medium leading-[24px]">
        {title}
        {isNew && (
          <span className="text-xs font-medium leading-[20px] bg-[#F2F5FF] text-[#4A72FF] px-2 py-[2px] rounded-[2px]">
            New
          </span>
        )}
      </div>
      <div>{children}</div>
    </section>
  ) : (
    false
  );
};

const SectionMetrics = ({ items }) => {
  const regular = items.filter((item) => !item.preview);
  const preview = items.filter((item) => item.preview);

  const [isOpen, setIsOpen] = useState(false);
  const [subject, setSubject] = useState("");
  const [integration, setIntegration] = useState("");
  const [metric, setMetric] = useState("");

  const openPopupWith = (integration, metric) => {
    setSubject(`Integration: ${integration}  Metric: ${metric}`);
    setIntegration(integration);
    setMetric(metric);
    setIsOpen(true);
  };

  const closePopup = () => {
    setIsOpen(false);
  };

  return (
    <div className="space-y-[60px]">
      <Results.SubSection show={regular.length > 0} title="">
        <Pagination
          Card={Metric}
          items={regular}
          className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-x-5 gap-y-5"
          id="metrics"
        />
      </Results.SubSection>

      <Modal isOpen={isOpen} onClose={closePopup}>
        <RequestForm
          title={"Request a new metric"}
          onClose={closePopup}
          subject={subject}
          integration={integration}
          metric={metric}
        />
      </Modal>

      <Results.SubSection
        show={preview.length > 0}
        title="Upcoming metrics"
        isNew={true}
      >
        <Pagination
          Card={MetricPreview}
          cardProps={{ openPopupWith: openPopupWith }}
          items={preview}
          className="grid grid-cols-1 lg:grid-cols-4 gap-x-5 gap-y-5"
          id="metrics"
        />
      </Results.SubSection>
    </div>
  );
};

const SearchInput = ({ onChange, value, show }) => {
  if (!show) return;

  return (
    <input
      type="search"
      placeholder="Search"
      className="py-2.5 pl-10 pr-3 border-[#D7DFE9] hover:border-[#AFBACA] focus:border-[#8897AE] outline-none text-[#455468] bg-white w-full rounded border hover:border  text-base leading-[20px] data-hj-allow"
      onChange={onChange}
      value={value}
      style={{
        background:
          "url('/search-large.svg') no-repeat  10px center, url('/line.svg') no-repeat 32px center, #fff",
      }}
    />
  );
};

const SearchInputIntegrations = ({ onChange, value, show }) => {
  if (!show) return;

  return (
    <div className="">
      <input
        type="search"
        placeholder="Search integrations"
        className="py-1.5 pl-9 pr-3 bg-[#F9FAFB] border-[#F9FAFB]  hover:border-[#AFBACA] focus:border-[#8897AE] outline-none text-[#455468] text-sm w-full rounded  border hover:border leading-[20px] data-hj-allow"
        onChange={onChange}
        value={value}
        style={{
          background: "url('/search-large.svg') no-repeat 10px center, #fff",
        }}
      />
    </div>
  );
};

// import React from "react";
// import TaxonomyTermIntegrationCategories from "./taxonomy/integration-categories";
// import BlockContentSectionContent from "./blocks/content-section";
// import ParagraphGenericBlock from "./paragraphs/generic-block";
// import SectionCardsAccross from "./blocks/section-cards-across";

const Component = ({ data, context, ...props }) => {
  //console.log( data )
  let comp = "";

  switch (data.__typename) {
    
    case "block_content__section_content":
      comp = (
        <BlockContentSectionContent data={data} context={context} {...props} />
      );
      break;

    default:
      comp = <pre>{JSON.stringify(data, null, 3)}</pre>;
      break;
  }

  return <>{comp}</>;
};



export default MarketplaceSearchPage;

export const query = graphql`
  query TemplateSearchPage {
    categories2: allMarketplaceTerm {
      nodes {
        __typename
        id
        name
        vocabulary
      }
    }
    templates2: allMarketplaceTemplate(sort: { fields: title, order: ASC }) {
      nodes {
        __typename
        id
        title
        slug
        one_liner

        bussiness_questions
        description
        category {
          id
          name
        }
        connectors {
          id
          name
        }
        persona {
          id
          name
        }
        workflows {
          id
          name
        }
        metrics {
          id
          _id
          title
          one_liner
          slug
          image {
            id
            height
            url
            alt
          }
        }
      }
    }
    metrics2: allMarketplaceMetric(sort: { fields: title, order: ASC }) {
      nodes {
        __typename
        id
        _id
        title
        slug
        one_liner
        alternative_names
        preview
        image {
          id
          height
          url
          alt
        }
        category {
          id
          name
        }
        connectors {
          id
          name
        }
        persona {
          id
          name
        }
        workflows {
          id
          name
        }
        tags {
          id
          name
        }
      }
    }
    block: blockContentSectionContent(
      id: { eq: "ec9e6cbf-bef7-5e05-b8bf-a7fecf95d71a" }
    ) {
      __typename
      id
      info
      relationships {
        content: field_content {
          ...ParagraphGenericBlock
        }
      }
    }
  }
`;
