const vocsNames = [
  { vocabulary: "category", name: "Category" },
  { vocabulary: "persona", name: "User" },
  { vocabulary: "workflows", name: "Processes" },
  { vocabulary: "connectors", name: "Integration" },
];

const extendsMetrics = (metrics, templates) => {
  //console.log(metrics)
  const all = metrics.map((m) => {
    let text = m.one_liner ? m.one_liner.replace(/(<([^>]+)>)/gi, "") : "";
    if (m?.description) {
      text += " " + m.description.replace(/(<([^>]+)>)/gi, "");
    }
    if (m.alternative_names && m.alternative_names.length) {
      m.alternative_names.forEach((name) => {
        text += " " + name;
      });
    }

    for (const v in vocsNames) {
      const voc = vocsNames[v].vocabulary;
      if (m[voc]) {
        for (const x in m[voc]) {
          text += " " + m[voc][x].name;
        }
      }
    }
    const m2 = extendMetric(m);
    //console.log( m2.terms )
    return {
      id: m2.id,
      metric: m2, // { ...m, connectors: getMetricConnectors(m, templates) },
      tags: m2.terms, //getMetricTerms(m, templates),
      text: (m.title + " " + text).toLowerCase(),
    };
  });
  return all;
};

const extendMetric = (metric /*, templates*/) => {
  // find all templates that use this metric
  // const relevantTemplates = templates.filter((t) => {
  //   const metricsIds = t.metrics.map((m) => m._id);
  //   return metricsIds.includes(metric._id);
  // });

  const connectors = metric.connectors.map((t) => t.name);

  const terms = [];
  const tags = [];

  //console.log(metric.category)
  if (metric.category) {
    metric.category.map((c) => {
      terms.push(c.id);
      tags.push(c.name);
      return null;
    });
  }

  if (metric.persona) {
    metric.persona.map((c) => {
      terms.push(c.id);
      tags.push(c.name);
      return null;
    });
  }

  if (metric.workflows) {
    metric.workflows.map((c) => {
      terms.push(c.id);
      tags.push(c.name);
      return null;
    });
  }

  if (metric.tags) {
    metric.tags.map((c) => {
      tags.push(c.name);
      return null;
    });
  }

  if (metric.connectors) {
    metric.connectors.map((c) => {
      terms.push(c.id);
      return null;
    });
  }

  return {
    ...metric,
    //templates: relevantTemplates,
    connectors: [...new Set(connectors)],
    terms: [...new Set(terms)],
    tags: [...new Set(tags)],
  };
};

const extendsTemplates = (templates) => {
  //console.log(templates)
  const all = templates.map((t) => {

    let text = '';
    for (const v in vocsNames) {
      const voc = vocsNames[v].vocabulary;
      if (t[voc]) {
        for (const x in t[voc]) {
          text += " " + t[voc][x].name;
        }
      }
    }


    return {
      id: t.id,
      template: t,
      tags: getTemplateTerms(t),
      text: (
        t.title +
        " " +
        t.description.replace(/(<([^>]+)>)/gi, "") +
        t.bussiness_questions.join(" ") + 
        " " + 
        text 
      )
        // add categories
        .toLowerCase(),
    };
  })
  .filter((t) => (t.template.metrics.length > 0))
  .filter((t) => (t.template.connectors.length > 0))
  return all;
};

const getTemplateTerms = (template) => {
  if (!template.connectors) {
    template.connectors = [];
  }
  const terms = [
    ...template.category.map((c) => c.id),
    ...template.connectors.map((c) => c.id),
    ...template.persona.map((c) => c.id),
    ...template.workflows.map((c) => c.id),
  ];
  return terms;
};

const intersect = (ids1, ids2) => {
  const ids = ids1.filter((x) => ids2.includes(x));
  return ids;
};

const filterObjects = (objects, userInput) => {
  //console.log(objects, userInput)
  const vnames = ["category", "persona", "workflows", "connectors"];
  userInput.vocs = {};
  for (const x in vnames) {
    userInput.vocs[vnames[x]] = [];
  }

  for (const x in userInput.tags) {
    var term = userInput.tags[x];
    userInput.vocs[term.vocabulary].push(term.id);
  }

  //console.log( userInput.vocs)

  //console.log(userInput)

  const groups = [];

  for (const x in vnames) {
    const termsIds = userInput.vocs[vnames[x]];
    if (termsIds.length > 0) {
      const objectsIds = objects
        .filter((t) => {
          const inter = intersect(t.tags, termsIds);
          return inter.length > 0;
        })
        .map((t) => t.id);

      groups.push(objectsIds);
    }
  }

  if (userInput.query !== "") {
    const lower = userInput.query.toLowerCase();
    const textSearchIds = objects
      .filter((t) => {
        return t.text.includes(lower);
      })
      .map((t) => t.id);
    groups.push(textSearchIds);
  }

  let all = objects.map((m) => m.id);
  groups.forEach((group) => {
    all = intersect(all, group);
  });

  return objects.filter((t) => all.includes(t.id));
};

const filterTemplates = (templates, userInput) => {
  return filterObjects(templates, userInput).map((t) => t.template);
};

const filterMatrics = (matrics, userInput) => {
  return filterObjects(matrics, userInput).map((t) => t.metric);
};

const getBgClassByCategpryName = (category) => {
  const categories = category ? category.map((c) => c.name) : [];
  categories.sort();
  const categoryName = categories.length > 0 ? categories[0] : "";

  let cardColor = "";

  switch (categoryName) {
    case "RevOps":
      cardColor = "bg-[#F1ECFE]";
      break;
    case "Sales":
      cardColor = "bg-[#EDFBFE]";
      break;
    case "Marketing":
      cardColor = "bg-[#EDFBF7]";
      break;

    default:
      cardColor = "bg-[#F2F5FF]";
      break;
  }

  return cardColor;
};

export {
  extendMetric,
  extendsMetrics,
  extendsTemplates,
  vocsNames,
  filterTemplates,
  filterMatrics,
  getBgClassByCategpryName,
  intersect
};
